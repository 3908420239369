// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

#fileloader
{

    margin-top: 5px;
}

#fileloader label
{

    margin-left: 5px;
    margin-right: 5px;
}

#fileloader input[type="number"]
{
    width: 50px;
}`, "",{"version":3,"sources":["webpack://./src/css/FileLoader.css"],"names":[],"mappings":";;AAEA;;;IAGI,eAAe;AACnB;;AAEA;;;IAGI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;;IAEI,WAAW;AACf","sourcesContent":["\r\n\r\n#fileloader\r\n{\r\n\r\n    margin-top: 5px;\r\n}\r\n\r\n#fileloader label\r\n{\r\n\r\n    margin-left: 5px;\r\n    margin-right: 5px;\r\n}\r\n\r\n#fileloader input[type=\"number\"]\r\n{\r\n    width: 50px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
