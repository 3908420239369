import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
export default function SboxEditModal({ sBox, setSBox }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (React.createElement(React.Fragment, null,
        React.createElement(FontAwesomeIcon, { icon: faPenToSquare, style: { cursor: 'pointer' }, onClick: handleShow }),
        React.createElement(Modal, { open: show, onClose: handleClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" },
            React.createElement(Box, { sx: style },
                React.createElement(Typography, { id: "modal-modal-title", variant: "h6", component: "h2" }, "Edit Sbox"),
                React.createElement(Typography, { id: "modal-modal-description", sx: { mt: 2 } },
                    React.createElement("textarea", { rows: 4, cols: 50, onChange: (e) => setSBox(e.target.value.split(' ')) }, sBox.join(' ')))))));
}
